import React from "react";


const ROUTES = [
  {
    path: "/login",
    securityType: "public",
    Component: React.lazy(() => import("../Pages/Login")),
    permission: "",
    condition: true,
  },
  {
    path: "/dashboard",
    securityType: "private",
    Component: React.lazy(() => import("../Pages/Dashboard")),
    permission: "",
    condition: true,
    breadcrumb: ['Home',"Dashboard"],
  },
  {
    path: "/domains",
    securityType: "private",
    Component: React.lazy(() => import("../Pages/Domains")),
    permission: "",
    condition: true,
    breadcrumb: ['Home',"Domains"],
  },
  {
    path: "/add-domain",
    securityType: "private",
    Component: React.lazy(() => import("../Pages/Domains/AddDomain")),
    permission: "",
    condition: true,
    breadcrumb: ['Home',"Add Domain"],
  },

  // not found page
  {
    path: "/*",
    securityType: "public",
    Component: React.lazy(() => import("../Pages/NotFound")),
    permission: "",
    condition: true,
    
  }
];

export default ROUTES;
