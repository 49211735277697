import React, { useState } from "react";
import { Layout } from "antd";
import Loader from "components/Loader";
import SidebarLander from "./components/SidebarLander";
import Header from "./components/Header";
import HeaderBreadcrumb from "components/HeaderBreadcrumb";

function AppLayout({ Component, activePath, breadcrumb }) {
  const [sideBarState, setSideBarState] = useState(false);
  return (
    <Layout>
      <Header />
      <Layout>
        <SidebarLander activePath={activePath} setSideBarState={setSideBarState} />
        <div
          className={`content-wrapper ${!sideBarState ? "sidebar-open" : ""}`}
        >
          <section className="content" id="sectionContent">
            <React.Suspense fallback={<Loader />}>
              <HeaderBreadcrumb activePath={activePath} items={breadcrumb} />
              <Component activePath={activePath} />
            </React.Suspense>
          </section>
        </div>
      </Layout>
      {/* <Footer /> */}
    </Layout>
  );
}

export default AppLayout;
