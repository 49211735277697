import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("scraperToken");

const client = axios.create({
  baseURL: "https://api.dataloop.tech/",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const get = (url, body, headers = {}) =>
  client.get(url, { params: body, headers });

const post = (url, body, headers = {}) => client.post(url, body, { headers });

const put = (url, body, headers = {}) => client.put(url, body, { headers });

const patch = (url, body, headers = {}) => client.patch(url, body, { headers });

const del = (url, params = {}, headers = {}) =>
  client.delete(url, { data: params, headers });

export { get, post, put, patch, del };
