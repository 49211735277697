import { Breadcrumb } from "antd";
import React from "react";

const HeaderBreadcrumb = ({ items = [], activePath }) => {
  console.log("items", items, activePath);
  return (
    <Breadcrumb
      style={{
        marginBottom: "14px",
      }}
      items={
        items &&
        items?.map((item, index) => {
          return {
            title: index === items.length - 1 ? <b>{item}</b> : item,
          };
        })
      }
    />
  );
};

export default HeaderBreadcrumb;
