import { Layout } from 'antd';
import React from 'react'
import { BugFilled } from '@ant-design/icons';


const { Header: AntdHeader } = Layout;

const Header = () => {
  return (
    <AntdHeader 
    className="header_main">
      <div className='logo'>
      <BugFilled />
      </div>
      </AntdHeader>
  )
}

export default Header