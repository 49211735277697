import { Button, Checkbox, Input, Form } from "antd";
import React from "react";
import { BugFilled } from "@ant-design/icons";
import { useFetchData } from "hooks/useFetchData";
import { post } from "utility/services";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
// import { Form } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [tableParams, setTableParams] = React.useState({
    username: "",
    password: "",
  });

  const onFinish = (values) => {
    console.log("Success:", values);
    setTableParams({
      username: values.username,
      password: values.password,
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const getData = (data) => post("/auth/login", data);

  const successCallback = (response) => {
    Cookies.set("scraperToken", response.data.token);
    navigate(`/dashboard`);
  };

  const [{ isLoading }] = useFetchData({
    apiFunction: getData,
    dependencyArray: [JSON.stringify(tableParams)],
    apiParams: { ...tableParams },
    apiCallCondition: tableParams.username && tableParams.password,
    showErrorMessage: true,
    successCallback: successCallback,
    errorMessage: "error",
    showSuccessMessage: false,
    successMessage: "success",
  });

  console.log("isLoading", isLoading);
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="login_container">
        <div className="login_wrapper">
          <div className="login_icon">
          <BugFilled />
          </div>
          <div className="title_text">
            <h1>Welcome Back!</h1>
            <p>Log in to your account to continue</p>
          </div>
          <Form
            name="basic"
            className="login_form"
            initialValues={{
              remember: true,
            }}
            requiredMark={false}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="User Name"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter your Username!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <div className="form_remember">
              <Form.Item
                className="remember"
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Button type="link">Forgot Password</Button>
            </div>
            <Form.Item className="login_button">
              <Button type="primary" htmlType="submit">
                Log in
              </Button>
            </Form.Item>
            <div className="form_signup">
              <p>Don't have an account?</p>
              <Button type="link">Sign up now</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
