import { useEffect, useState } from "react";
import GlobalMessage from "components/GlobalMessage";
import { message } from "antd";
const useFetchData = ({
  apiFunction,
  showLoadingMessage,
  apiParams,
  showErrorMessage,
  errorMessage,
  showSuccessMessage,
  successMessage,
  dependencyArray,
  apiCallCondition,
  successCallback,
  errorCallback,
  hasDefaultResponse,
  defaultResponse,
}) => {
  const [state, setState] = useState({
    isLoading: false,
    isError: false,
    data: [],
  });

  const { isLoading, isError, data } = state;
  useEffect(() => {
    if (hasDefaultResponse) {
      setState({
        ...state,
        isLoading: false,
        isError: false,
        data: defaultResponse,
      });
      successCallback && successCallback(defaultResponse, "defaultResponse");
    } else if (apiCallCondition) {
      setState({
        ...state,
        isLoading: true,
      });
      const loadingMessage = showLoadingMessage
        ? GlobalMessage.loading("Loading...", 0)
        : "";
      apiFunction(apiParams)
        .then((res) => {
          if (
            res?.ok ||
            res?.status === 200 ||
            res?.data?.status === 200 ||
            res?.data?.status_code === 200 ||
            res?.data?.statusCode === 200
          ) {
            showLoadingMessage && message.destroy(loadingMessage);
            setState({
              ...state,
              isLoading: false,
              isError: false,
              data: res?.data?.payload || res?.data || {},
            });
            successCallback &&
              successCallback(
                res?.data?.payload || res?.data?.body || res?.data || {},
                res?.data
              );
            showSuccessMessage &&
              console.log(successMessage || res.data.message);
          } else {
            showLoadingMessage && message.destroy(loadingMessage);
            setState({
              ...state,
              isLoading: false,
              isError: true,
              data: [],
            });
            showErrorMessage &&
              GlobalMessage.error(
                res?.data?.message || message || errorMessage
              );
          }
        })
        .catch((error) => {
          showLoadingMessage && message.destroy(loadingMessage);
          setState({
            ...state,
            isLoading: false,
            isError: true,
            data: [],
          });
          showErrorMessage &&
            GlobalMessage.error(
              errorMessage ||
                error?.response?.data?.error ||
                error?.response?.data?.error?.message ||
                error?.response?.data?.message ||
                error?.message
            );
          errorCallback && errorCallback(error?.response?.data);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray);

  return [{ isLoading, isError, data }];
};

export { useFetchData };