import React from "react";
import { Navigate } from "react-router-dom";
import AppLayout from "layout";
import Cookies from "js-cookie";
import Loader from "components/Loader";

const AuthRoutes = ({
  securityType,
  path,
  permission,
  condition,
  Component,
  breadcrumb,
}) => {
  if (securityType === "private" && Cookies.get("scraperToken")) {
    if (condition) {
      return <AppLayout activePath={path} Component={Component} breadcrumb={breadcrumb} />;
    }

    return (
      <AppLayout>
        <React.Suspense fallback={<Loader />}>
          {/* <AccessDenied /> */}
        </React.Suspense>
      </AppLayout>
    );
  }
  if (securityType === "public") {
    return <Component />;
  }
  return <Navigate to="/login" replace />;
};

export default AuthRoutes;
