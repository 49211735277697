import Login from "Pages/Login";
import ROUTES from "Routes";
import AuthRoutes from "Routes/AuthRoutes";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

function App() {
  const PERMISSIONS = true;
  return (
    <BrowserRouter>
    <Routes>
      <Route
        exact
        path="/"
        element={PERMISSIONS ? <Navigate to="/dashboard" replace /> : <Login />}
      />
      {ROUTES.map(({ Component, securityType, path, permission, condition, breadcrumb }) => (
        <Route
          exact
          path={path}
          key={path}
          element={
            <AuthRoutes
              Component={Component}
              securityType={securityType}
              path={path}
              permission={permission}
              condition={condition}
              breadcrumb={breadcrumb}
            />
          }
        />
      ))}
    </Routes>
  </BrowserRouter>
  );
}

export default App;
